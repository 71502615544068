import React from 'react';

const NoUserIcon = () => {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="User No Plus">
                <circle id="Ellipse 6" cx="21.4597" cy="21.4597" r="21.4597" fill="#2D2D2D" />
                <g id="Mask group">
                    <mask id="mask0_1085_1295" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="43">
                        <circle id="Ellipse 5" cx="21.5" cy="21.5" r="21.5" fill="#2D2D2D" />
                    </mask>
                    <g mask="url(#mask0_1085_1295)">
                        <g id="Group 6">
                            <path id="Vector 1" d="M36.1426 36.1426L6.77673 35.5779V38.4015L9.03565 39.531L11.2946 40.6604L13.5535 41.7899L15.8124 42.9193H19.2007H24.848H25.9775H27.1069L29.3658 41.7899L31.6248 40.6604L33.8837 39.531L36.1426 37.2721V36.1426Z" fill="white" />
                            <path id="Vector" d="M26.6982 19.8224C26.6982 19.8224 27.6249 15.9314 26.6982 14.6122C25.7716 13.2952 25.4031 12.4172 23.3656 11.7887C21.3281 11.1601 22.0705 11.2854 20.5889 11.3497C19.1063 11.414 17.8722 12.2288 17.8722 12.6689C17.8722 12.6689 16.9456 12.731 16.5771 13.1079C16.2064 13.4848 15.5894 15.2407 15.5894 15.6797C15.5894 16.1187 15.8979 19.0719 16.2064 19.6982L15.839 19.8246C15.5294 23.464 17.0709 23.9041 17.0709 23.9041C17.6269 27.2941 18.1829 25.8507 18.1829 26.7287C18.1829 27.6067 17.6269 27.2941 17.6269 27.2941C17.6269 27.2941 17.1342 28.6742 15.9001 29.1764C14.666 29.6775 7.81647 32.3757 7.2583 32.9411C6.70013 33.5064 6.78079 36.0473 6.78079 36.0473L36.1402 36.1426C36.1402 36.1426 36.2056 33.5075 35.6475 32.9411C35.0915 32.3757 28.2398 29.6775 27.0057 29.1764C25.7716 28.6742 25.2788 27.2941 25.2788 27.2941C25.2788 27.2941 24.7229 27.6067 24.7229 26.7287C24.7229 25.8507 25.2788 27.2941 25.8348 23.9041C25.8348 23.9041 27.3752 23.464 27.0667 19.8246H26.6961L26.6982 19.8224Z" fill="white" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};

export default NoUserIcon;